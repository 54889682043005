import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="prose mx-auto text-prose-xl">
      <h1>{`Amsterdam Strand`}</h1>
      <p>{`2017`}</p>
      <p>{`18x24in`}</p>
      <p>{`Acrylic on canvas`}</p>
      <p>{`Available: contact for price.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/32ab2c4fc486aa8c4229c957990367ff/9568a/IMG_0303.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgUD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHWVy7ocFy4Xh3Lz0ucg//EAB4QAAICAQUBAAAAAAAAAAAAAAABAgMyBBESEyIx/9oACAEBAAEFAmkSicR2Nv4uwrhu9RL2mU4TyP/EABgRAAIDAAAAAAAAAAAAAAAAAAABEBIh/9oACAEDAQE/AXpSf//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBES/9oACAECAQE/AU6Nz//EABoQAAIDAQEAAAAAAAAAAAAAAAABEBExAhL/2gAIAQEABj8CNjT1FC5WKLHH/8QAHhAAAgMAAQUAAAAAAAAAAAAAAAERITFBUXGRofD/2gAIAQEAAT8hZ5RZS+T7ZAVJGb63CR2EMmv0dBEpsaGTWy7nskI//9oADAMBAAIAAwAAABBrNf8A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxAA6WLHLG//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAgEBPxBrYWLo4f/EACAQAQACAgICAwEAAAAAAAAAAAEAESExQVFhkYGh4fD/2gAIAQEAAT8QAX3MW9G84Ewcqvcb9HBb+yjGrS6NtfUubHwRveLA5YBgSgV/eIKTYxuNUBgtbmcl5L8TVjjuf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "amsterdam strand",
              "title": "amsterdam strand",
              "src": "/static/32ab2c4fc486aa8c4229c957990367ff/4b190/IMG_0303.jpg",
              "srcSet": ["/static/32ab2c4fc486aa8c4229c957990367ff/e07e9/IMG_0303.jpg 200w", "/static/32ab2c4fc486aa8c4229c957990367ff/066f9/IMG_0303.jpg 400w", "/static/32ab2c4fc486aa8c4229c957990367ff/4b190/IMG_0303.jpg 800w", "/static/32ab2c4fc486aa8c4229c957990367ff/e5166/IMG_0303.jpg 1200w", "/static/32ab2c4fc486aa8c4229c957990367ff/b17f8/IMG_0303.jpg 1600w", "/static/32ab2c4fc486aa8c4229c957990367ff/9568a/IMG_0303.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      